<template>
  <div>
    <message-search-form
      :initQuery="query"
      :initFilters="initFilters"
      :filterTypes="filteredFilterTypes"
      :initDocId="docId"
      @removeDocId="removeDocId"
      @manualMessageSearchStart="doMessageSearch"
    />
    <message-search-results
      :mediaSearchUrl="mediaSearchUrl"
      :chatTimelineUrl="chatTimelineUrl"
      :state="resultsState"
      :messages="messagesObj"
      :isOverLimit="isOverLimit"
      :totalMessages="totalMessages"
      @removeDocId="removeDocId"
      @bookmarkRemoved="bookmarkRemoved"
    />
    <section>
      <back-to-top-button />
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import MessageSearchForm from './MessageSearchForm'
import MessageSearchResults from './MessageSearchResults'
import BackToTopButton from './BackToTopButton.vue'

export default {
  name: 'MessageSearch',

  components: {
    MessageSearchForm,
    MessageSearchResults,
    BackToTopButton
  },

  props: {
    apiUrl: {
      type: String,
      required: true
    },
    mediaSearchUrl: {
      type: String,
      required: true
    },
    chatTimelineUrl: {
      type: String,
      required: true
    },
    initLimit: {
      type: Number,
      required: true
    },
    initStart: {
      type: Number,
      required: true
    },
    initQuery: {
      type: String,
      required: true
    },
    initFilters: {
      type: String,
      required: true
    },
    initSortPreference: {
      type: String,
      default: 'desc'
    },
    initDocId: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      messagesObj: {},
      totalMessages: 0,
      resultsState: 'loading',
      filterTypes: {
        'forum': 'Forum',
        'forum_id': 'Forum ID',
        // 'message': 'Message', #26230
        'post_id': 'Post ID',
        'posted_date': 'Posted date',
        'site_name': 'Site name',
        'title': 'Title',
        'topic': 'Topic',
        'topic_id': 'Topic ID',
        // 'url': 'URL', #26230
        'user_id': 'User ID',
        // 'userinfo': 'User info', #26230
        'username': 'Username',
        'bookmarked': 'Bookmarked'
      },
      // fields which are encoded  coming from backend/url
      // this has to match utils/elasticsearch.py.URL_ENCODED_FIELDS
      URL_ENCODED_FIELDS: [],
      isOverLimit: false,
      docId: '',
      refreshLimit: 25,
      refreshStart: 0,
      refreshQuery: '',
      refreshFilters: '',
      refreshSortPreference: 'desc',
      params: null,
      initialParams: {
        filters: this.initFilters,
        limit: this.initLimit,
        posted_date: this.initSortPreference,
        query: this.initQuery,
        start: this.initStart
      },
      replaceState: true
    }
  },

  computed: {
    limit() {
      return this.$store.state.limit
    },
    start() {
      return this.$store.state.start
    },
    query() {
      return this.$store.state.query
    },
    filters() {
      return this.$store.state.filters
    },
    sortPreference() {
      return this.$store.state.sortPreference
    },
    filteredFilterTypes() {
      const filteredTypes = {...this.filterTypes}
      delete filteredTypes.bookmarked
      return filteredTypes
    },
    dataUpdate() {
      const {
        limit,
        start,
        filters,
        sortPreference
      } = this
      return {
        limit,
        start,
        filters,
        sortPreference
      }
    },

  },

  watch: {
    dataUpdate: {
      handler: function(value) {
        this.resultsState = 'loading'
        this.$nextTick(() => this.updateSearch())
      },
      deep: true
    },
  },
  created() {
    axios.get('/en/message-search/get_url_encoded_filter_fields/')
      .then((response) => {
        this.URL_ENCODED_FIELDS = response.data
      }).catch((error) => console.log(error))

    if (this.initFilters) {
      let initFiltersArray = this.handleInitialFilters(this.initFilters)
      this.$store.commit('addFilters', initFiltersArray)
    }

    this.$store.commit('query', this.initQuery)
    this.$store.commit('limit', this.initLimit)
    // Sometimes `start` begins at -1 and that's quite annoying:
    let start = this.initStart < 0 ? 0 : this.initStart
    this.$store.commit('start', start)
    this.$store.commit('setSortPreference', this.initSortPreference)

    this.docId = this.initDocId
  },

  mounted() {
    // Don't make a call more than twice a second
    // this.debouncedUpdate = debounce(this.updateSearch, 500)
    // let url = new URL(window.location)
    // this seems to fire before the data block is loaded, so get it to chill for a moment
    // this.$nextTick(() => this.updateSearch())
    window.addEventListener('popstate', (e) => {
      // console.log('heard popstate', e, window.location.search)

      let search = (window.location.search) ? window.location.search : this.initialParams
      let prevParams = new URLSearchParams(search)
      // if you hit "back" when first viewing the page (say from the home page) e.state doesn't have a value so
      // it will throw an error.  so we'll set some defaults here to prevent that
      // TODO: this seems to get us "stuck" on page 1 of search.  Hitting back will just take us to page one of search regardless of where we came from.  Leaving for now to get things merged but circle back and adjust this ASAP
      if (e.state) {
        this.refreshLimit = e.state.limit
        this.refreshStart = e.state.start
        this.refreshQuery = e.state.query
        this.refreshFilters = e.state.filters
        this.refreshSortPreference = (e.state.sortPreference) ? e.state.sortPreference : this.refreshSortPreference
      }
      this.refreshStore({
        limit: this.refreshLimit,
        start: this.refreshStart,
        query: this.refreshQuery,
        filters: this.handleInitialFilters(this.refreshFilters),
        sortPreference: this.refreshSortPreference
      })
      this.params = prevParams
      this.replaceState = true
    })
  },

  methods: {
    refreshStore(updatedParams) {
      this.$store.commit('emptyFilters')
      if (updatedParams.filters) {
        this.$store.commit('addFilters', updatedParams.filters)
      }
      if (updatedParams.limit) {
        this.$store.commit('limit', updatedParams.limit)
      }
      if (updatedParams.start) {
        this.$store.commit('start', updatedParams.start)
      }
      // We only accept
      if (updatedParams.sortPreference === 'asc') {
        this.$store.commit('setSortPreferenceOldestFirst')
      } else if (updatedParams.sortPreference === 'desc') {
        this.$store.commit('setSortPreferenceNewestFirst')
      } else {
        this.$store.commit('setSortPreference', 'relevance')
      }
      this.$store.commit('query', updatedParams.query)
    },

    handleInitialFilters(filters) {
      if (filters) {
        let splitFilters = filters.split(',')
        // this comes from django request.get so will some will be base64 encoded

        return splitFilters.reduce((acc, term) => {
          // Set split's limit to 2 so it stops after the first colon
          let splits = term.split(':', 2)

          // Current filter type must be in filterTypes,
          // and current filter term must have a value of some kind
          if (splits[0] in this.filterTypes && splits[1] !== '') {
            if (this.URL_ENCODED_FIELDS.includes(splits[0])) {
              splits[1] = decodeURIComponent(splits[1])
            }
            acc.push({
              type: splits[0],
              term: splits[1],
            })
          }
          return acc
        }, [])
      }
    },

    fetchMessages() {
      this.resultsState = 'loading'

      // console.log('fetching messages with params', params)

      axios.get(this.apiUrl, {params: this.params})
        .then(response => {
          this.messagesObj = response
          this.totalMessages = response.data.total
          this.resultsState = 'retrieved'
          this.isOverLimit = response.data.isOverLimit
          if (this.totalMessages < this.start) {
            this.$store.commit('start', 0)
          }
        })
        .catch(e => {
          console.error(e)
          this.resultsState = 'error'
        })
    },

    getUpdatedParams(currentParams) {
      let filtersConcat = ''
      let paramData = {
        query: this.query,
        limit: this.limit,
        start: this.start,
        filters: this.filters,
      }
      // these come from the filters so they should always be undecoded, so let's decode any
      // before we send to backend
      if (paramData && paramData.filters && paramData.filters.length) {
        filtersConcat = paramData.filters.reduce((filterString, term, index) => {
          let termString = term.term
          if (this.URL_ENCODED_FIELDS.includes(term.type)) {
            termString = encodeURIComponent(termString)
          }
          return `${filterString}${filterString.length > 0 ? ',' : ''}${term.type}:${termString}`
        }, '')
      }

      let updatedParamsObj = {
        query: paramData.query,
        limit: paramData.limit,
        start: paramData.start,
        filters: filtersConcat,
        ...currentParams
      }
      updatedParamsObj = {
        posted_date: this.sortPreference,
        ...updatedParamsObj
      }
      return updatedParamsObj
    },

    handleURL() {
      let url = new URL(window.location)
      for (let key in this.params) {
        if (this.params.hasOwnProperty(key)) {
          url.searchParams.set(key, this.params[key])
        }
        if (key === 'doc_id') {
          this.docId = this.params[key]
        }
      }

      if (this.replaceState === false) {
        window.history.pushState(this.params, 'Forum search', url)
      } else {
        window.history.replaceState(this.params, 'Forum search', url)
        this.replaceState = false
      }
    },

    updateSearch(fromUrl = null) {
      // console.log('fromUrl?', fromUrl)
      let url = fromUrl || new URL(window.location)
      let extraParams = {}

      // These params will be populated from the search automatically.
      // So don't carry these forward.
      const skipUpdate = [
        'query',
        'limit',
        'start',
        'filters',
        'posted_date'
      ]

      for (let key of url.searchParams.keys()) {
        if (!(skipUpdate.includes(key))) {
          extraParams[key] = url.searchParams.get(key)
        }
      }

      // reset start if query or filters have changed
      let currentQuery = url.searchParams.get('query')
      let urlFilters = url.searchParams.get('filters')
      if (urlFilters) {
        let filtersArray = this.handleInitialFilters(urlFilters)
        if (JSON.stringify(filtersArray) !== JSON.stringify(this.filters)) {
          this.$store.commit('start', 0)
        }
      } else if (currentQuery !== this.query) {
        this.$store.commit('start', 0)
      }

      this.params = this.getUpdatedParams(extraParams)

      this.handleURL()
      this.fetchMessages()
    },

    removeDocId(updateSearch = false) {
      this.docId = ''
      let url = new URL(window.location)
      url.searchParams.delete('doc_id')
      window.history.pushState(this.params, 'Forum search', url)
      this.replaceState = true
      this.updateSearch(url)
    },

    bookmarkRemoved() {
      this.fetchMessages()
    },

    doMessageSearch() {
      this.resultsState = 'loading'
      this.updateSearch()
    }

  },

}

</script>
